$boxWidth: 260px;

.service {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 60px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    width: $boxWidth * 2 + 40px;
    margin: 0 auto 60px auto;
  }

  @media (max-width: 620px) {
    flex-wrap: wrap;
    width: $boxWidth;
    margin: 0 auto 40px auto;
  }

  &Box {
    width: 23%;
    background: #ffffff;
    border: 1px solid #097f00;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    //transition: 0.1s background-color linear;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    transition: box-shadow 200ms;
    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    }
    @media (max-width: 900px) {
      //width: 50%;
      flex-basis: $boxWidth;
      max-width: $boxWidth;
      margin: 10px;
    }

    p {
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      padding-bottom: 10px;
      font-size: 12px;
      line-height: 14px;
      //transition: 0.1s color linear;
    }
    .btn {
      margin-bottom: 25px;
      margin-left: 30px;
      margin-right: 30px;
      pointer-events: none;
    }
    /*&:hover {
      background-color: $green;
      .serviceTitle {
        background-color: $white !important;
        color: $green;
      }
      p {
        color: $white;
      }
    }*/
    &.-black {
      /*&:hover {
        border: 1px solid $white;
      }*/
    }
  }

  &Title {
    background: #097f00;
    border-radius: 3px 3px 20px 20px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    text-align: center;
    //transition: 0.1s color linear;
  }
}

.-servicesBg {
  background-image: url(../img/bgs/slider2-min.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
