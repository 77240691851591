.refs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    width: $boxWidth*2 + 40px;
    margin: 0 auto 60px auto;
  }

  @media (max-width: 620px) {
    flex-wrap: wrap;
    width: $boxWidth;
    margin: 0 auto 40px auto;
  }
  .ref {
    width: 23%;
    @media (max-width: 900px) {
      //width: 50%;
      flex-basis: $boxWidth;
      max-width: $boxWidth;
      margin: 10px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}