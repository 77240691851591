.container {
  margin: 0 auto;
  max-width: 1440px;
}
.content {
  max-width: 1140px;
  width: 100%;
  //padding-left: 150px;
  //padding-right: 150px;
  overflow: hidden;
  margin: 0 auto;
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.flex {
  display: flex;
}
