/*
 * CUSTOM FONT EXAMPLE
 */

/*@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Regular-webfont.eot);
  src: url(custom/DroidSerif-Regular-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Regular-webfont.woff2) format('woff2'), url(custom/DroidSerif-Regular-webfont.woff) format('woff'), url(custom/DroidSerif-Regular-webfont.ttf) format('truetype'), url(custom/DroidSerif-Regular-webfont.svg#DroidSerif-Regular-webfont) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Italic-webfont.eot);
  src: url(custom/DroidSerif-Italic-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Italic-webfont.woff2) format('woff2'), url(custom/DroidSerif-Italic-webfont.woff) format('woff'), url(custom/DroidSerif-Italic-webfont.ttf) format('truetype'), url(custom/DroidSerif-Italic-webfont.svg#DroidSerif-Italic-webfont) format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Bold-webfont.eot);
  src: url(custom/DroidSerif-Bold-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Bold-webfont.woff2) format('woff2'), url(custom/DroidSerif-Bold-webfont.woff) format('woff'), url(custom/DroidSerif-Bold-webfont.ttf) format('truetype'), url(custom/DroidSerif-Bold-webfont.svg#DroidSerif-Bold-webfont) format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-BoldItalic-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-BoldItalic-webfont.woff2) format('woff2'), url(custom/DroidSerif-BoldItalic-webfont.woff) format('woff'), url(custom/DroidSerif-BoldItalic-webfont.ttf) format('truetype'), url(custom/DroidSerif-BoldItalic-webfont.svg#DroidSerif-BoldItalic-webfont) format('svg');
  font-weight: bold;
  font-style: italic;
}
*/

body {
  font-family: 'Roboto', sans-serif !important;
  color: $black;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

h3 {
  font-style: normal;
  font-weight: normal;
  color: $green;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

h4 {
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  margin-top: 0;
}

a {
  @include linx-simple($black, $green);
}

p {
  margin-top: 0;
  font-size: 13px;
  line-height: 17px;
  color: $black;
}
p.-s12 {
  font-size: 12px;
  line-height: 16px;

}
