.clearfix {
  zoom: 1;
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.verticalCenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
