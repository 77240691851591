footer {
  background-color: $lightBlack;
  color: $white;
  p {
    font-size: 14px;
    color: $white;
  }
  a {
    @include linx-simple($white, $green);
  }
}

.footer {
  &Flex {
    display: flex;
    justify-content: space-between;
    @media (max-width: 800px) {
      flex-direction: column;
    }

  }
  &Box {
    width: 30%;
    padding-top: 75px;
    padding-bottom: 75px;
    &.-left {
      text-align: left;
      @media (max-width: 800px) {
        padding-top: 50px;
        padding-bottom: 0px;
        text-align: center;
      }
    }
    &.-middle {
      text-align: center;
      @media (max-width: 800px) {
        padding-top: 20px;
        padding-bottom: 0px;
      }
    }
    &.-right {
      text-align: right;
      @media (max-width: 800px) {
        padding-top: 20px;
        padding-bottom: 50px;
        text-align: center;
      }

    }
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}

.bmMapa {
  text-align: left;
  margin-top: 10px;
  max-width: 290px;
  width: 100%;
}

div.hr {
  height: 1px;
  border-bottom: 1px solid $white;
  width: 100%;
  max-width: 240px;
  margin-bottom: 15px !important;
  margin-top: 15px !important;
  &.-c {
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    margin: auto;
  }
}