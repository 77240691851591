#poptavka-form {
  padding-top: 30px;
  text-align: center;
}

#poptavka-form div {
  width: 80%;
  text-align: left;
  padding: 10px 0;
  margin: 0 auto;
}

#poptavka-form div label {
  padding-left: 5px;
  display: block;
  margin-bottom: 10px;
  //color:
}

#poptavka-form div input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: solid 1px silver;
  outline: none;
  padding-left: 10px;
  //color:
}

#poptavka-form .btn {
  margin: 50px 0 30px 0;
  cursor: pointer;
}
