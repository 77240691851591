table {
  width: 100%;
  th,td {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    padding-left: 15px;
    padding-right: 15px;
    &:first-child {
      text-align: left;
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
    @media (max-width: $tableResp) {
      font-size: 11px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  tr {
    border-bottom: 1px solid #C4C4C4;
    &:first-child {
      border-top: 1px solid #C4C4C4;
    }
  }

  th.-b,td.-b {
    font-weight: bold;
  }

  th.-l, td.-l {
    text-align: left !important;
  }
  th.-c, td.-c {
    text-align: center;
  }
  th.-r, td.-r {
    text-align: right;
  }
  th.-br, td.-br {
    border-right: 1px solid #C4C4C4;;
  }
  th.-bl, td.-bl {
    border-left: 1px solid #C4C4C4;;
  }
  margin-bottom: 25px;
}

.tableWrapper {
  &.-responsive {
    @media (max-width: 500px) {
      overflow-y: scroll;
      table {
        width: 500px;
      }

    }
  }
}

