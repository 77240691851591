@mixin linx-simple ($base, $hover) {
  color: $base;
  transition: all 0.2s ease 0s;
  /*&:visited {
    color: $base;
  }*/
  &:hover {
    color: $hover;
  }
  /*&:active {
    color: $hover;
  }*/
}
