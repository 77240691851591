.headerLink {
  font-size: 14px;
  font-weight: bold;
  &:before {
    position: relative;
    right: 10px;
    display: inline-block;
    content: ' ';
    background-size: auto;
    background-repeat: no-repeat;
  }

  &.-email {
    padding-left: 10px;
    &:before {
      background-image: url('../img/icons/email.svg');
      width: 20px;
      height: 17px;
      right: 10px;
      top: 4px;
    }
  }
  &.-phone {
    padding-left: 10px;
    &:before {
      background-image: url('../img/icons/phone.svg');
      width: 18px;
      height: 18px;
      right: 10px;
      top: 3px;
    }
  }
}

.btn {
  display: inline-block;
  background-color: $green;
  color: $white;
  border-radius: 5px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 15px;
  &:hover {
    background-color: $darkGreen;
    color: $white;
    /*color: $green;
    background-color: $white;*/
  }
  &.-reverse {
    color: $green;
    background-color: $white;
    &:hover {
      color: $green;
      background-color: $white;

    }
  }
  &.-shaddow {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
  }
  &.-border {
    border: 1px solid $green;
  }
  &.-small {
    height: 40px;
    line-height: 38px;
    font-size: 14px;
  }
  &.-mw225 {
    min-width: 225px;
    max-width: 225px;
  }
  &.-mw195 {
    min-width: 195px;
    max-width: 195px;
  }
  &.-grey {
    color: #494949;
    &:hover {
      color: #494949;
    }
  }
  &.-greyActive {
    background-color: #494949;
    color: $white;
    &:hover {
      color: $white;
      background-color: #000000;
    }
  }
  &.-greyBorder {
    border: 1px solid #494949;
  }
}