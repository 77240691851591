.-textCenter {
  text-align: center;
}

.trPreload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.-ulMargin {
  li {
    margin-bottom: 8px;
    list-style: none;
    font-size: 14px;
    line-height: 16px;
    &:before {
      color: #757575;
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      font-size: 20px;
      margin-left: -1em; /* Also needed for space (tweak if needed) */
      top: 1px;
      position: relative;
    }
  }
}
