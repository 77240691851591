.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  margin: 20px auto 20px auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 75%; /* Could be more or less, depending on screen size */
  max-width: 850px;
  position: relative;
  padding-top: 30px;
  @media (max-width: 700px) {
    width: 100%;
    margin: 0;
  }
}

/* The Close Button */
.close {
  position: absolute;
  right: 20px;
  top: 10px;
  color: #aaa;
  margin-left: auto;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modalButtons {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  .btn {
    margin: 5px;
    text-overflow: initial;
    overflow: inherit;
  }
}
