$brHeader: 1100px;
.header {
  display: flex;
  height: 95px;
  @media (max-width: $brHeader) {
    height: 50px;
  }
  @media (max-width: 900px) {
    height: auto;
    flex-direction: column;
  }

  &Images {
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
      justify-content: center;
      margin-top: 10px;
    }
  }
  &Logo {
    height: 75px;
    margin-right: 25px;
    @media (max-width: $brHeader) {
      height: 50px;
    }
    @media (max-width: 420px) {
      margin-right: 0;
    }
  }


  &Text{
    height: 31px;
    @media (max-width: 420px) {
      display: none;
    }
  }
}

.headerLinks {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  @media (max-width: 900px) {
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  @media (max-width: 420px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
}
.headerLink {
  &:first-child {
    margin-right: 30px;
    @media (max-width: 420px) {
      margin-right: 0;
    }
  }
  @media (max-width: 420px) {
    margin-bottom: 10px;
  }
}


$navHeight: 44px;
header {
  nav {
    .content {
      @media (max-width: 420px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      height: $navHeight;
      @media (max-width: 400px) {
        justify-content: center;
      }
      li {
        border-right: 1px solid $white;
        &:last-child {
          border-right: none;
        }

        a {
          display: block;
          height: $navHeight;
          line-height: $navHeight;
          padding: 0 15px;
          color: $white;
          font-size: 14px;
          @media (max-width: 400px) {
            font-size: 13px;
            padding: 0 10px;
          }
          &:hover {
            color: $white;
            background-color: $darkGreen;
          }
        }
      }
    }
  }
}

.intro {
  height: 320px;
  @media (max-width: 800px) {
    height: auto;
  }
  //background-image: linear-gradient( rgba(255, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../img/bgs/slider-min.jpg);
  background-image: url(../img/bgs/slider-min.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  h1 {
    padding-top: 16px;
  }
  .introBtns {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    @media (max-width: 500px) {
      flex-direction: column;
    }
    @media (max-width: 800px) {
      margin-bottom: 50px;
    }
    .btn {
      margin: 0 15px;
      @media (max-width: 500px) {
        margin: 0 auto;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}