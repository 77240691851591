/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.-white {
  color: #ffffff; }

.-black {
  color: #000000; }

.-green {
  color: #097F00; }

.-bgWhite {
  background-color: #ffffff; }

.-bgBlack {
  background-color: #000000; }

.-bgGreen {
  background-color: #097F00; }

.-bgDarkGreen {
  background-color: #065500; }

.-bgLightBlack {
  background-color: #2D2D2D !important; }

html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%; }

body {
  box-sizing: border-box; }

* {
  box-sizing: inherit; }

a {
  text-decoration: none; }

button {
  cursor: pointer; }

@-moz-document url-prefix() {
  .remove-me {
    border: 1px solid red;
    position: absolute;
    right: 0;
    top: 0; } }

.clearfix {
  zoom: 1; }
  .clearfix:before, .clearfix:after {
    content: "";
    display: table; }
  .clearfix:after {
    clear: both; }

.verticalCenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

/*
 * CUSTOM FONT EXAMPLE
 */
/*@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Regular-webfont.eot);
  src: url(custom/DroidSerif-Regular-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Regular-webfont.woff2) format('woff2'), url(custom/DroidSerif-Regular-webfont.woff) format('woff'), url(custom/DroidSerif-Regular-webfont.ttf) format('truetype'), url(custom/DroidSerif-Regular-webfont.svg#DroidSerif-Regular-webfont) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Italic-webfont.eot);
  src: url(custom/DroidSerif-Italic-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Italic-webfont.woff2) format('woff2'), url(custom/DroidSerif-Italic-webfont.woff) format('woff'), url(custom/DroidSerif-Italic-webfont.ttf) format('truetype'), url(custom/DroidSerif-Italic-webfont.svg#DroidSerif-Italic-webfont) format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-Bold-webfont.eot);
  src: url(custom/DroidSerif-Bold-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-Bold-webfont.woff2) format('woff2'), url(custom/DroidSerif-Bold-webfont.woff) format('woff'), url(custom/DroidSerif-Bold-webfont.ttf) format('truetype'), url(custom/DroidSerif-Bold-webfont.svg#DroidSerif-Bold-webfont) format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'DroidSerif';
  src: url(custom/DroidSerif-BoldItalic-webfont.eot?#iefix) format('embedded-opentype'), url(custom/DroidSerif-BoldItalic-webfont.woff2) format('woff2'), url(custom/DroidSerif-BoldItalic-webfont.woff) format('woff'), url(custom/DroidSerif-BoldItalic-webfont.ttf) format('truetype'), url(custom/DroidSerif-BoldItalic-webfont.svg#DroidSerif-BoldItalic-webfont) format('svg');
  font-weight: bold;
  font-style: italic;
}
*/
body {
  font-family: 'Roboto', sans-serif !important;
  color: #000000; }

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center; }

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center; }

h3 {
  font-style: normal;
  font-weight: normal;
  color: #097F00;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px; }

h4 {
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  margin-top: 0; }

a {
  color: #000000;
  transition: all 0.2s ease 0s;
  /*&:visited {
    color: $base;
  }*/
  /*&:active {
    color: $hover;
  }*/ }
  a:hover {
    color: #097F00; }

p {
  margin-top: 0;
  font-size: 13px;
  line-height: 17px;
  color: #000000; }

p.-s12 {
  font-size: 12px;
  line-height: 16px; }

.-textCenter {
  text-align: center; }

.trPreload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important; }

.-ulMargin li {
  margin-bottom: 8px;
  list-style: none;
  font-size: 14px;
  line-height: 16px; }
  .-ulMargin li:before {
    color: #757575;
    content: "\2022";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    font-weight: bold;
    /* If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    /* Also needed for space (tweak if needed) */
    font-size: 20px;
    margin-left: -1em;
    /* Also needed for space (tweak if needed) */
    top: 1px;
    position: relative; }

.container {
  margin: 0 auto;
  max-width: 1440px; }

.content {
  max-width: 1140px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto; }
  @media (max-width: 1200px) {
    .content {
      padding-left: 20px;
      padding-right: 20px; } }

.flex {
  display: flex; }

.header {
  display: flex;
  height: 95px; }
  @media (max-width: 1100px) {
    .header {
      height: 50px; } }
  @media (max-width: 900px) {
    .header {
      height: auto;
      flex-direction: column; } }
  .headerImages {
    display: flex;
    align-items: center; }
    @media (max-width: 900px) {
      .headerImages {
        justify-content: center;
        margin-top: 10px; } }
  .headerLogo {
    height: 75px;
    margin-right: 25px; }
    @media (max-width: 1100px) {
      .headerLogo {
        height: 50px; } }
    @media (max-width: 420px) {
      .headerLogo {
        margin-right: 0; } }
  .headerText {
    height: 31px; }
    @media (max-width: 420px) {
      .headerText {
        display: none; } }

.headerLinks {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto; }
  @media (max-width: 900px) {
    .headerLinks {
      margin-right: auto;
      margin-top: 10px;
      margin-bottom: 20px; } }
  @media (max-width: 420px) {
    .headerLinks {
      flex-direction: column;
      margin-bottom: 10px; } }

.headerLink:first-child {
  margin-right: 30px; }
  @media (max-width: 420px) {
    .headerLink:first-child {
      margin-right: 0; } }

@media (max-width: 420px) {
  .headerLink {
    margin-bottom: 10px; } }

@media (max-width: 420px) {
  header nav .content {
    padding-left: 0;
    padding-right: 0; } }

header nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  height: 44px; }
  @media (max-width: 400px) {
    header nav ul {
      justify-content: center; } }
  header nav ul li {
    border-right: 1px solid #ffffff; }
    header nav ul li:last-child {
      border-right: none; }
    header nav ul li a {
      display: block;
      height: 44px;
      line-height: 44px;
      padding: 0 15px;
      color: #ffffff;
      font-size: 14px; }
      @media (max-width: 400px) {
        header nav ul li a {
          font-size: 13px;
          padding: 0 10px; } }
      header nav ul li a:hover {
        color: #ffffff;
        background-color: #065500; }

.intro {
  height: 320px;
  background-image: url(../img/bgs/slider-min.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media (max-width: 800px) {
    .intro {
      height: auto; } }
  .intro h1 {
    padding-top: 16px; }
  .intro .introBtns {
    margin-top: 50px;
    display: flex;
    justify-content: center; }
    @media (max-width: 500px) {
      .intro .introBtns {
        flex-direction: column; } }
    @media (max-width: 800px) {
      .intro .introBtns {
        margin-bottom: 50px; } }
    .intro .introBtns .btn {
      margin: 0 15px; }
      @media (max-width: 500px) {
        .intro .introBtns .btn {
          margin: 0 auto; }
          .intro .introBtns .btn:first-child {
            margin-bottom: 20px; } }

.headerLink {
  font-size: 14px;
  font-weight: bold; }
  .headerLink:before {
    position: relative;
    right: 10px;
    display: inline-block;
    content: ' ';
    background-size: auto;
    background-repeat: no-repeat; }
  .headerLink.-email {
    padding-left: 10px; }
    .headerLink.-email:before {
      background-image: url("../img/icons/email.svg");
      width: 20px;
      height: 17px;
      right: 10px;
      top: 4px; }
  .headerLink.-phone {
    padding-left: 10px; }
    .headerLink.-phone:before {
      background-image: url("../img/icons/phone.svg");
      width: 18px;
      height: 18px;
      right: 10px;
      top: 3px; }

.btn {
  display: inline-block;
  background-color: #097F00;
  color: #ffffff;
  border-radius: 5px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 15px; }
  .btn:hover {
    background-color: #065500;
    color: #ffffff;
    /*color: $green;
    background-color: $white;*/ }
  .btn.-reverse {
    color: #097F00;
    background-color: #ffffff; }
    .btn.-reverse:hover {
      color: #097F00;
      background-color: #ffffff; }
  .btn.-shaddow {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15); }
  .btn.-border {
    border: 1px solid #097F00; }
  .btn.-small {
    height: 40px;
    line-height: 38px;
    font-size: 14px; }
  .btn.-mw225 {
    min-width: 225px;
    max-width: 225px; }
  .btn.-mw195 {
    min-width: 195px;
    max-width: 195px; }
  .btn.-grey {
    color: #494949; }
    .btn.-grey:hover {
      color: #494949; }
  .btn.-greyActive {
    background-color: #494949;
    color: #ffffff; }
    .btn.-greyActive:hover {
      color: #ffffff;
      background-color: #000000; }
  .btn.-greyBorder {
    border: 1px solid #494949; }

.service {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 60px; }
  @media (max-width: 900px) {
    .service {
      flex-wrap: wrap;
      width: 560px;
      margin: 0 auto 60px auto; } }
  @media (max-width: 620px) {
    .service {
      flex-wrap: wrap;
      width: 260px;
      margin: 0 auto 40px auto; } }
  .serviceBox {
    width: 23%;
    background: #ffffff;
    border: 1px solid #097f00;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    transition: box-shadow 200ms;
    /*&:hover {
      background-color: $green;
      .serviceTitle {
        background-color: $white !important;
        color: $green;
      }
      p {
        color: $white;
      }
    }*/ }
    .serviceBox:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25); }
    @media (max-width: 900px) {
      .serviceBox {
        flex-basis: 260px;
        max-width: 260px;
        margin: 10px; } }
    .serviceBox p {
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      padding-bottom: 10px;
      font-size: 12px;
      line-height: 14px; }
    .serviceBox .btn {
      margin-bottom: 25px;
      margin-left: 30px;
      margin-right: 30px;
      pointer-events: none; }
    .serviceBox.-black {
      /*&:hover {
        border: 1px solid $white;
      }*/ }
  .serviceTitle {
    background: #097f00;
    border-radius: 3px 3px 20px 20px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-align: center; }

.-servicesBg {
  background-image: url(../img/bgs/slider2-min.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.tut {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between; }
  @media (max-width: 1000px) {
    .tut {
      flex-direction: column; } }
  .tutPreWrap {
    width: 29.1%;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1130px) {
      .tutPreWrap {
        width: 29.6%; } }
    @media (max-width: 1070px) {
      .tutPreWrap {
        width: 29.7%; } }
    @media (max-width: 1050px) {
      .tutPreWrap {
        width: 29.9%; } }
    @media (max-width: 1030px) {
      .tutPreWrap {
        width: 30.2%; } }
    @media (max-width: 1020px) {
      .tutPreWrap {
        width: 30.4%; } }
    @media (max-width: 1000px) {
      .tutPreWrap {
        margin: 0 auto;
        width: 300px; } }
    @media (max-width: 400px) {
      .tutPreWrap {
        width: 280px; } }
  .tutBox {
    width: 125px;
    background: #FFFFFF;
    border: 1px solid #097F00;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 22px 0 22px;
    transition: background-color 0.2s linear; }
    @media (max-width: 1000px) {
      .tutBox {
        width: 125px; } }
    @media (max-width: 400px) {
      .tutBox {
        width: 115px; } }
    .tutBox p {
      text-align: center;
      color: #757575;
      transition: color 0.2s linear; }
    .tutBoxImg {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .tutBoxImg path {
        transition: fill 0.2s linear; }
    .tutBox:hover {
      background-color: #097F00; }
      .tutBox:hover p {
        color: #ffffff; }
      .tutBox:hover .tutBoxImg path {
        fill: #ffffff !important; }

.tutArrow {
  width: 28px;
  display: flex;
  align-items: center; }
  .tutArrow:before {
    display: block;
    content: ' ';
    width: 29px;
    height: 12px;
    background-image: url(../img/icons/arrow.svg); }
  @media (max-width: 1000px) {
    .tutArrow.-canRotate {
      margin-top: 40px;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto;
      transform: rotate(90deg); } }
  @media (max-width: 1000px) {
    .tutArrow.-canRotate {
      margin-top: 30px;
      margin-bottom: 30px; } }

.tutorial .btnWrap {
  margin-top: 30px;
  padding-bottom: 20px; }

.-tutorialBg {
  background-image: url(../img/bgs/leseni-bg-min.jpg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 600px; }

.refs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px; }
  @media (max-width: 900px) {
    .refs {
      flex-wrap: wrap;
      width: 560px;
      margin: 0 auto 60px auto; } }
  @media (max-width: 620px) {
    .refs {
      flex-wrap: wrap;
      width: 260px;
      margin: 0 auto 40px auto; } }
  .refs .ref {
    width: 23%; }
    @media (max-width: 900px) {
      .refs .ref {
        flex-basis: 260px;
        max-width: 260px;
        margin: 10px; } }
    .refs .ref img {
      display: block;
      width: 100%; }

footer {
  background-color: #2D2D2D;
  color: #ffffff; }
  footer p {
    font-size: 14px;
    color: #ffffff; }
  footer a {
    color: #ffffff;
    transition: all 0.2s ease 0s;
    /*&:visited {
    color: $base;
  }*/
    /*&:active {
    color: $hover;
  }*/ }
    footer a:hover {
      color: #097F00; }

.footerFlex {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 800px) {
    .footerFlex {
      flex-direction: column; } }

.footerBox {
  width: 30%;
  padding-top: 75px;
  padding-bottom: 75px; }
  .footerBox.-left {
    text-align: left; }
    @media (max-width: 800px) {
      .footerBox.-left {
        padding-top: 50px;
        padding-bottom: 0px;
        text-align: center; } }
  .footerBox.-middle {
    text-align: center; }
    @media (max-width: 800px) {
      .footerBox.-middle {
        padding-top: 20px;
        padding-bottom: 0px; } }
  .footerBox.-right {
    text-align: right; }
    @media (max-width: 800px) {
      .footerBox.-right {
        padding-top: 20px;
        padding-bottom: 50px;
        text-align: center; } }
  @media (max-width: 800px) {
    .footerBox {
      width: 100%; } }

.bmMapa {
  text-align: left;
  margin-top: 10px;
  max-width: 290px;
  width: 100%; }

div.hr {
  height: 1px;
  border-bottom: 1px solid #ffffff;
  width: 100%;
  max-width: 240px;
  margin-bottom: 15px !important;
  margin-top: 15px !important; }
  div.hr.-c {
    margin: 0 auto; }
  @media (max-width: 800px) {
    div.hr {
      margin: auto; } }

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }

/* Modal Content/Box */
.modal-content {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  margin: 20px auto 20px auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 75%;
  /* Could be more or less, depending on screen size */
  max-width: 850px;
  position: relative;
  padding-top: 30px; }
  @media (max-width: 700px) {
    .modal-content {
      width: 100%;
      margin: 0; } }

/* The Close Button */
.close {
  position: absolute;
  right: 20px;
  top: 10px;
  color: #aaa;
  margin-left: auto;
  font-size: 28px;
  font-weight: bold; }

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer; }

.modalButtons {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto; }
  .modalButtons .btn {
    margin: 5px;
    text-overflow: initial;
    overflow: inherit; }

table {
  width: 100%;
  margin-bottom: 25px; }
  table th, table td {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    padding-left: 15px;
    padding-right: 15px; }
    table th:first-child, table td:first-child {
      text-align: left;
      padding-left: 0; }
    table th:last-of-type, table td:last-of-type {
      padding-right: 0; }
    @media (max-width: 700px) {
      table th, table td {
        font-size: 11px;
        padding-top: 10px;
        padding-bottom: 10px; } }
  table tr {
    border-bottom: 1px solid #C4C4C4; }
    table tr:first-child {
      border-top: 1px solid #C4C4C4; }
  table th.-b, table td.-b {
    font-weight: bold; }
  table th.-l, table td.-l {
    text-align: left !important; }
  table th.-c, table td.-c {
    text-align: center; }
  table th.-r, table td.-r {
    text-align: right; }
  table th.-br, table td.-br {
    border-right: 1px solid #C4C4C4; }
  table th.-bl, table td.-bl {
    border-left: 1px solid #C4C4C4; }

@media (max-width: 500px) {
  .tableWrapper.-responsive {
    overflow-y: scroll; }
    .tableWrapper.-responsive table {
      width: 500px; } }

#poptavka-form {
  padding-top: 30px;
  text-align: center; }

#poptavka-form div {
  width: 80%;
  text-align: left;
  padding: 10px 0;
  margin: 0 auto; }

#poptavka-form div label {
  padding-left: 5px;
  display: block;
  margin-bottom: 10px; }

#poptavka-form div input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: solid 1px silver;
  outline: none;
  padding-left: 10px; }

#poptavka-form .btn {
  margin: 50px 0 30px 0;
  cursor: pointer; }
