html, body {
  padding: 0;
  margin: 0;
  width:100%;
  height: 100%;
}

body {
  box-sizing: border-box;
  //padding-top: 5px;
}
* {
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

@-moz-document url-prefix() {
  .remove-me {
    border:1px solid red;
    position:absolute;
    right:0;
    top:0;
  }
}