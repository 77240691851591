$white: #ffffff;
$dartWhite: #F9F9F9;
$black: #000000;
$green: #097F00;
$darkGreen: #065500;
$lightBlack: #2D2D2D;
$lightText: #757575;

.-white {
  color: $white;
}

.-black {
  color: $black;
}

.-green {
  color: $green;
}

.-bgWhite {
  background-color: $white;
}
.-bgBlack {
  background-color: $black;
}
.-bgGreen {
  background-color: $green;
}
.-bgDarkGreen {
  background-color: $darkGreen;
}
.-bgLightBlack {
  background-color: $lightBlack !important;
}




