.tut {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-direction: column;
  }

  &PreWrap {
    width: 29.1%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1130px) {
      width: 29.6%;
    }
    @media (max-width: 1070px) {
      width: 29.7%;
    }
    @media (max-width: 1050px) {
      width: 29.9%;
    }
    @media (max-width: 1030px) {
      width: 30.2%;
    }
    @media (max-width: 1020px) {
      width: 30.4%;
    }

    @media (max-width: 1000px) {
      margin: 0 auto;
      width: 300px;

    }
    @media (max-width: 400px) {
      width: 280px;
    }
  }
    &Box {
    width: 125px;
    background: #FFFFFF;
    border: 1px solid #097F00;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0 22px 0 22px;
    transition: background-color 0.2s linear;
      @media (max-width: 1000px) {
        width: 125px;
      }
      @media (max-width: 400px) {
        width: 115px;
      }
    p {
      text-align: center;
      color: $lightText;
      transition: color 0.2s linear;
    }
    &Img {
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      path {
        transition: fill 0.2s linear;
      }
    }

    &:hover {
      background-color: $green;
      p {
        color: $white;
      }
      .tutBoxImg  {
        path {
          fill: $white !important;
        }
      }
    }

  }
}

.tutArrow {
  width: 28px;
  display: flex;
  align-items: center;
  &:before {
    display: block;
    content: ' ';
    width: 29px;
    height: 12px;
    background-image: url(../img/icons/arrow.svg);
  }
  &.-canRotate {
    @media (max-width: 1000px) {
      margin-top: 40px;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto;
      transform: rotate(90deg);
    }
    @media (max-width: 1000px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.tutorial {
  //background-color: whitesmoke;
  .btnWrap {
    margin-top: 30px;
    padding-bottom: 20px;
  }
}

.-tutorialBg {
  background-image: url(../img/bgs/leseni-bg-min.jpg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: auto 600px;
}